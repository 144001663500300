<template>
	<div class="test">
		<div class="page_1">
			<PageNav :show_num="4" />
		</div>
		<div class="page_2">
			<div class="page_2_1">
				<div class="page_2_1_bot">
					<div class="page_0_item" @click="go_solut(item.id)" v-for="(item,index) in solution_list" :key="index" :style="{backgroundImage:'url('+item.img+')'}" >
						<div class="item-name">
							{{item.name}}
						</div>
					</div>
				</div>
				
				<div class="more-class">
					More solutions are being added…
				</div>

			</div>
		</div>

		<PageBot :show_num="4" />
		<el-backtop class="back_up" target="" :bottom="100" :visibility-height="620" :right="10">
			<svg class="icon icon_xiangshang" aria-hidden="true">
				<use xlink:href="#icon-xiangshang"></use>
			</svg>
		</el-backtop>
	</div>
</template>

<script>
	import PageNav from "@/components/PageNav.vue"
	import PageBot from "@/components/PageBottom.vue"
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	import {
		resetRem
	} from '@/all_js/all';
	import {
		clickEffect
	} from "@/all_js/click";
	export default {
		name: 'about',
		components: {
			PageBot,
			PageNav
		},
		created() {
			// resetRem(); // 根据1920尺寸设置rem，1rem=100px
			// clickEffect(); // 引入鼠标点击特效
		},
		watch: {
		},

		mounted() {

		},
		data() {
			return {
				avtive_nav: "",
				show_device_detail: false,
				device_detail: {},
				currentSwiper: null,
				currentSwipers: null,
				solution_list:[
					{
						id:0,
						name:"Mobile Payment Solution",
						msg:"With the development of mobile internet technology and the popularization of smartphones, the convenient, fast, and secure mobile payment becoming common.We provide an overall mobile payment solution to help our partner start their own mobile payment business.",
						img:require('../../assets/images/solut_0s.png'),
						active:true
					},
					{
						id:1,
						name:"Smart Hospital Solution",
						msg:"Through online service portal and Kiosks, to provide overall patient services covering pre-visit, during-visit and post-visit. Greatly reduce queuing time, simplify the payment and inquiry transactions, and provide personalized patient care, thus improve efficiency and patient satisfaction.",
						img:require('../../assets/images/solut_1s.png'),
						active:false
					},
					{
						id:2,
						name:"Smart Branch Solutio",
						msg:"Through online service portal and Kiosks, to proviThe smart branch solution provides a customer facing self-service portal, supporting around 300 banking transactions, and cross-platform monitoring and management functions, to improve efficiency and customer satisfaction, help branch focus more on customer service and marketing promotion.de overall patient services covering pre-visit, during-visit and post-visit. Greatly reduce queuing time, simplify the payment and inquiry transactions, and provide personalized patient care, thus improve efficiency and patient satisfaction.",
						img:require('../../assets/images/solut_2s.png'),
						active:false
					},
					{
						id:3,
						name:"Digital Taxation",
						msg:"Through online service portal and Kiosks, to proviThe smart branch solution provides a customer facing self-service portal, supporting around 300 banking transactions, and cross-platform monitoring and management functions, to improve efficiency and customer satisfaction, help branch focus more on customer service and marketing promotion.de overall patient services covering pre-visit, during-visit and post-visit. Greatly reduce queuing time, simplify the payment and inquiry transactions, and provide personalized patient care, thus improve efficiency and patient satisfaction.",
						img:require('../../assets/images/solut_4.png'),
						active:false
					}
				],
			}
		},
		methods: {
			go_solut(id){
				this.$router.push({
					path: '/solut-' + id,
					query: {
						id: '123456'
					},
				})
				
			},
			go_contact() {
				this.$router.push({
					path: '/contact',
					query: {
						id: '123456'
					},

				})
			},
		}
	}
</script>
<style scoped lang="less">
	* {
		margin: 0;
		padding: 0;
		// transition: all 1s; // 设置动画效果
	}

	li {
		list-style-type: none;
	}

	.page_1 {
		width: 100%;
		height: 580px;
		object-fit: cover; // 保持图片缩放时候不变形
		background-image: url("../../assets/images/soluts/total_bg.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none; // 子元素无法被选中
	}



	.back_up {
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		background-color: #49b1f5;

		.icon_xiangshang {
			height: 30px;
			width: 30px;
			border-radius: 30px;
		}
	}

	.back_up:hover {
		background-color: #49b1f5;
	}
</style>
<style scoped lang="less">
	// @media only screen and (min-width:1371px) {}
	
	
		.page_2 {
			background: #FAFAFA;
			padding-bottom: 100px;
	
			.page_2_1 {
				width: 1360px;
				margin: 0 auto;
			}
	
			.page_2_1_top {
				padding-top: 100px;
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
	
				.top-title {
					color: #000000;
					font-size: 48px;
				}
	
				.bot-line {
					margin-top: 20px;
					width: 127px;
					height: 4px;
					background-color: #0081CC;
				}
	
			}
	
			.page_2_1_bot {
				width: 100%;
				margin-top: 80px;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
	
				.page_0_item,.page_1_item,.page_2_item,.page_3_item {
					width: 400px;
					height: 300px;
					margin-bottom: 30px;
					display: flex;
					flex-direction: column;
					cursor: pointer;
					justify-content: flex-end;
					object-fit: cover; // 保持图片缩放时候不变形
					background-color: #ffffff;
					background-image: url("../../assets/images/solut_0s.png");
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center center;
					border-radius: 5px;
					.item-name {
						width: 100%;
						background: rgba(0,0,0,0.7);
						border-radius: 0px 0px 10px 10px;
						height: 55px;
						font-size: 20px;
						color: #ffffff;
						display: flex;
						align-items: center;
						justify-content: center;
					}
	
				}
				.page_0_item{
					background-image: url("../../assets/images/solut_0s.png");
				}
				.page_1_item{
					background-image: url("../../assets/images/solut_1s.png");
				}
				.page_2_item{
					background-image: url("../../assets/images/solut_2s.png");
				}
				.page_3_item{
					background-image: url("../../assets/images/solut_4.png");
				}
	
	
			}
			.more-class{
				width: 100%;
				margin-top: 180px;
				text-align: center;
				color: #BABABA;
				font-size: 24px;
			}
		}
	
	
</style>